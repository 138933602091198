import * as React from 'react'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { TextField } from '@shared/components/text-field'
import { useSmsRetry } from '@shared/hooks/use-sms-retry'
import cx from 'clsx'
import { Form, FormikProvider, useFormik } from 'formik'

import { ApplicationFormContext } from '../../../Application'

import { sendSmsCode } from './api'

import styles from './ConfirmSmsForm.module.scss'

type FormStatuses = 'wrong-code' | 'error'

export const ConfirmSmsForm = React.memo(
	({ codeConfig = {} }: { codeConfig: Record<string, any> }) => {
		const context = React.useContext(ApplicationFormContext)

		const phone = context.formController.values!.participants[0].phone

		const formik = useFormik({
			initialValues: {
				code: '',
			},
			onSubmit: async ({ code }, { setStatus }) => {
				try {
					const result = await httpService.post<Record<string, any>>(
						apiUrls.esia.verifySMSCode(code, Object.entries(context.optionalDocsIds)
						.filter(([, value]) => value)
						.map(([id]) => id))
					)

					if (result.verified) {
						context.cookieController.update(true)
						context.stepController.go.forward?.()
					} else {
						setStatus('wrong-code' as FormStatuses)
					}
				} catch (error: any) {
					if (error?.response?.status === 401) {
						setStatus('wrong-code' as FormStatuses)
					} else {
						setStatus('error' as FormStatuses)
					}
				}
			},
		})

		const formStatus: FormStatuses = formik.status

		const onSendSms = React.useCallback(async () => {
			formik.setSubmitting(true)

			try {
				await sendSmsCode(phone)
			} finally {
				formik.setSubmitting(false)
			}
		}, [])

		const { isRunning, text, retry } = useSmsRetry(
			onSendSms,
			codeConfig.durationBetweenCodeRequests
		)

		const codeProps: React.ComponentProps<typeof TextField> = {
			mask: '9'.repeat(codeConfig.codeLength || 0),
			maskChar: '',
			required: true,
			onChange: formik.handleChange,
			value: formik.values.code,
			className: styles.input,
			inputMode: 'numeric',
			error: formStatus === 'wrong-code' && '',
			name: 'code',
			label: 'Код из смс',
			disabled: formik.isSubmitting,
		}

		React.useEffect(() => {
			if (formik.values.code.length === codeConfig.codeLength) {
				formik.submitForm()
			}
		}, [formik.values.code, formik.submitForm, codeConfig.codeLength])

		return (
			<FormikProvider value={formik}>
				<Form autoComplete='off'>
					<h4 className={styles.title}>Подпишите документы</h4>
					<p className={cx(styles.text, styles.indentXs)}>
						Мы отправили на номер {phone} смс с кодом.
					</p>
					<p className={cx(styles.text, styles.indentS)}>
						Введите его, чтобы завершить оформление заявки:
					</p>
					<TextField {...codeProps} />
					<div className={styles.bottom}>
						<span
							className={cx(styles.linkBtn, {
								[styles.disabled]: isRunning || formik.isSubmitting,
							})}
							onClick={!isRunning ? retry : undefined}
						>
							{text}
						</span>
						{formStatus === 'wrong-code' && (
							<span className={styles.error}>Неправильный код подтверждения</span>
						)}
						{formStatus === 'error' && (
							<span className={styles.error}>Произошла ошибка, попробуйте ещё раз</span>
						)}
					</div>
				</Form>
			</FormikProvider>
		)
	}
)
