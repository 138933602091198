import React from 'react'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'

const PageAnimation = ({ children }) => {
	const { asPath } = useRouter()

	return (
		<motion.div
			className='motion'
			layoutId={asPath}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ delay: 0.2 }}
			style={{ height: '100%' }}
		>
			{children}
		</motion.div>
	)
}

export default PageAnimation
