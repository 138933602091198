import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { transformPhoneToDto } from '@http/service/shared-transformers'
import { consoleDebug, isDebug } from '@shared/pipes/is-debug'

export const sendSmsCode = (phone: string) => {
	if (isDebug()) {
		return consoleDebug('[Primary form] - prevent send form')
	}

	return httpService.post(apiUrls.sms.send(transformPhoneToDto(phone)))
}
