import React, { PropsWithChildren } from 'react'
import cx from 'classnames'

import { StateProps } from './model'

import styles from './style.module.scss'

export const View = ({ children, className, ...rest }: PropsWithChildren<StateProps>) => {
	return (
		<div className={cx(styles.root, className)} {...rest}>
			{children}
		</div>
	)
}
