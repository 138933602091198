import * as React from 'react'

import { ApplicationFormContext } from '../../Application'
import { Card } from '../../components/Card'

import { ConfirmSmsForm } from './ConfirmSmsForm'
import { SendSmsForm } from './SendSmsForm'

import styles from './ConfirmApplication.module.scss'

export const ConfirmApplication = () => {
	const context = React.useContext(ApplicationFormContext)
	const [codeConfig, setCodeConfig] = React.useState({
		codeLength: null,
		durationBetweenCodeRequests: null,
	})

	const [activeForm, setActiveForm] = React.useState<'send' | 'confirm'>('send')

	const onSuccessSend = React.useCallback(() => setActiveForm('confirm'), [])

	return (
		<Card
			header={
				<>
					<div className={styles.step}>
						{`Шаг ${context.stepController.order} из ${context.stepController.total}`}
					</div>
					<div className={styles.hint}>Заявка на кредит</div>
				</>
			}
		>
			<h2 className={styles.h2}>Подтвердите заявку</h2>
			{activeForm === 'send' && (
				<SendSmsForm onSuccess={onSuccessSend} setCodeConfig={setCodeConfig} />
			)}
			{activeForm === 'confirm' && <ConfirmSmsForm codeConfig={codeConfig} />}
		</Card>
	)
}
