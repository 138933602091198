import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import {
	DEFAULT_NEW_LOAN_SUM,
	DEFAULT_NEW_PERIOD_YEAR,
	DEFAULT_PERCENT_YEAR,
	DEFAULT_PERIOD,
	PROPERTY_VALUE_RATIO,
} from '@constants/loan'
import { isLeadProductType, LeadProductType } from '@features/types'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { formatPhone } from '@shared/utils/helpers'
import {
	CROSS_APPLICATION_FORM_COOKIE,
	LOCAL_APPLICATION_FORM_COOKIE,
	NEW_CROSS_APPLICATION_FORM_COOKIE,
} from 'constants/cookies'

import { ApplicationFormDto, StepKeys } from './types'

export const transformDtoToForm = (data?: Record<string, any>): ApplicationFormDto => ({
	loan: data?.calculation?.loan ?? DEFAULT_NEW_LOAN_SUM,
	percent: data?.calculation?.percent ?? DEFAULT_PERCENT_YEAR,
	period: Math.round((data?.calculation?.period ?? DEFAULT_PERIOD) / 12) ?? DEFAULT_NEW_PERIOD_YEAR,
	payment: data?.calculation?.payment ?? PROPERTY_VALUE_RATIO * DEFAULT_NEW_LOAN_SUM,
	type: data?.calculation?.type ?? 'ANNUITY',
	purpose: data?.marketingData?.purpose ?? '',
	participant: {
		fullname: [
			data?.participants[0]?.surname,
			data?.participants[0]?.name,
			data?.participants[0]?.patronymic,
		]
			.filter(Boolean)
			.join(' '),
		name: data?.participants[0]?.name ?? '',
		surname: data?.participants[0]?.surname ?? '',
		patronymic: data?.participants[0]?.patronymic ?? '',
		email: data?.participants[0]?.email ?? '',
		phone: formatPhone(data?.participants[0]?.phone ?? '', true),
		role: data?.participants[0]?.role ?? 'APPLICANT',
	},
	address: data?.address || null,
	sameAddresses: data?.sameAddresses || true,
	owner: data?.owner || 'single',
	participants: data?.participants || [],
	product: isLeadProductType(data?.product) ? data?.product : 'REAL_ESTATE_LOAN',
})

interface UseFormControllerValue {
	isLoading: boolean
	hasLead: boolean
	setOptionalDocsIds: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
	optionalDocsIds: Record<string, boolean>
	setHasLead: (value: boolean) => void
	hasEsia: boolean
	setHasConfirmed: (value: boolean) => void
	setHasBonused: (value: boolean) => void
	currentStep: StepKeys
	currentStepNumber: number
	formController: {
		refetch: (onSuccess?: () => void) => Promise<void>
		values: ApplicationFormDto
		setValues: (values: ApplicationFormDto) => void
	}
	setFormCookie: (value: string) => void
	logout: () => void
}

export const useFormController = (
	isFullPage: boolean,
	type?: LeadProductType | undefined
): UseFormControllerValue => {
	const [cookie, setCookie, removeCookie] = useCookies([
		NEW_CROSS_APPLICATION_FORM_COOKIE,
		CROSS_APPLICATION_FORM_COOKIE,
		LOCAL_APPLICATION_FORM_COOKIE,
	])
	const [isLoading, setIsLoading] = useState(true)
	const [formValues, setFormValues] = useState<ApplicationFormDto>(
		transformDtoToForm({ product: type, participants: [] })
	)
	const [hasLead, setHasLead] = useState(false)
	const [hasEsia, setHasEsia] = useState(false)
	const [optionalDocsIds, setOptionalDocsIds] = useState<Record<string, boolean>>({})
	const [hasConfirmed, setHasConfirmed] = useState(false)
	const [hasBonused, setHasBonused] = useState(false)

	const logout = useCallback(async () => {
		removeCookie(LOCAL_APPLICATION_FORM_COOKIE)
		removeCookie(CROSS_APPLICATION_FORM_COOKIE)
		removeCookie(NEW_CROSS_APPLICATION_FORM_COOKIE)
		try {
			await httpService.post(apiUrls.esia.logout())
		} catch {
			console.log('logout error')
		}
	}, [removeCookie])

	useEffect(() => {
		const loader = async () => {
			if (!isFullPage && cookie[NEW_CROSS_APPLICATION_FORM_COOKIE] === 'completed') {
				await logout()
				initialize()
			}
		}
		loader()
	}, [cookie.NEW_CROSS_APPLICATION_FORM_COOKIE, hasEsia])

	const initialize = useCallback(async (onSuccess?: () => void) => {
		setIsLoading(true)
		onSuccess && onSuccess()
		try {
			const response = await httpService.get<Record<string, any>>(apiUrls.lead.get())
			setFormValues(transformDtoToForm(response))
			setHasLead(true)
		} catch (error) {
			setHasLead(false)
			setHasEsia(false)
			setIsLoading(false)
			return
		}

		try {
			const user = await httpService.get<Record<string, any>>(apiUrls.getUser())
			setHasEsia(!!user.esiaSession)
		} catch (error) {
			console.dir(error)
			setHasEsia(false)
		}
		setIsLoading(false)
	}, [])

	useEffect(() => {
		initialize()
	}, [initialize])

	const currentStep = useMemo<StepKeys>(() => {
		// 'primary' | 'esia-auth' | 'confirm' | 'bonus' | 'success'
		if (hasEsia && !hasConfirmed && cookie[NEW_CROSS_APPLICATION_FORM_COOKIE] === 'completed') {
			return 'success'
		}

		if (hasConfirmed) {
			return 'success'
		}

		if (hasEsia) {
			return 'confirm'
		}

		if (hasLead) {
			return 'esia-auth'
		}

		return 'primary'
	}, [hasLead, hasEsia, hasConfirmed, hasBonused, cookie.NEW_CROSS_APPLICATION_FORM_COOKIE])

	const currentStepNumber = useMemo(() => {
		switch (currentStep) {
			case 'primary':
				return 1
			case 'esia-auth':
				return 3
			default:
				return 4
		}
	}, [currentStep])

	const setFormCookie = useCallback(
		(value: string) => {
			setCookie(NEW_CROSS_APPLICATION_FORM_COOKIE, value, {
				domain: process.env.NODE_ENV === 'development' ? undefined : '.credit.club',
			})
		},
		[setCookie]
	)

	return {
		isLoading,
		hasLead,
		setHasLead,
		hasEsia,
		setHasConfirmed,
		setHasBonused,
		currentStep,
		currentStepNumber,
		setOptionalDocsIds,
		optionalDocsIds,
		formController: {
			refetch: initialize,
			values: formValues,
			setValues: setFormValues,
		},
		setFormCookie,
		logout,
	}
}
