import * as React from 'react'
import { useUserDocuments } from '@features/userDocuments'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { Button } from '@shared/components/button'
import { ApplicationFormContext } from '@shared/components/forms/application/local'
import CheckboxChecked from '@shared/svg/checkbox/checked.svg'
import CheckboxNormal from '@shared/svg/checkbox/normal.svg'

import styles from './SendSmsForm.module.scss'

interface Props {
	onSuccess: () => void
	setCodeConfig: (data: any) => void
}

const svgIconInlineStyle = { width: 'initial', height: 'initial' }

export const SendSmsForm = ({ onSuccess, setCodeConfig }: Props) => {
	const { getPdfDataUri, fetchDocumentsList, documentsList } = useUserDocuments()!
	const context = React.useContext(ApplicationFormContext)

	React.useEffect(() => {
		;(async () => {
			const data = await fetchDocumentsList()

			if (data)
				context.setOptionalDocsIds(
					data
						.filter(({ required }) => !required)
						.reduce((acc, { id }) => {
							acc[id] = true
							return acc
						}, {})
				)
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchDocumentsList])

	const handleSendSms = React.useCallback(async (event: any) => {
		try {
			event.preventDefault()
			const data = await httpService.post<Record<string, any>>(apiUrls.esia.getSignConfig())
			setCodeConfig(data)
			onSuccess()
		} catch {
			//
		}
	}, [])

	const notRequiredDocs = documentsList.filter(({ required }) => !required)

	return (
		<form onSubmit={handleSendSms} autoComplete='off'>
			{!!documentsList.length && (
				<>
					Нажимая кнопку «Далее», я даю своё согласие и подтверждаю, что ознакомился со следующим
					списком документов: <br />
					{documentsList.map(({ name, id }, i) => (
						<div key={name}>
							{i + 1}.{' '}
							<a
								className='main-link'
								target='_blank'
								rel='noopener noreferrer'
								href={getPdfDataUri(id)}
							>
								{name}
							</a>
							.
						</div>
					))}
					{!!notRequiredDocs.length && (
						<div className={styles.grid}>
							{notRequiredDocs.map(({ id, description }) => (
								<FormControlLabel
									key={id}
									label={description}
									control={
										<Checkbox
											onChange={(_, checked) => {
												context.setOptionalDocsIds((p) => ({ ...p, [id]: checked }))
											}}
											checked={context.optionalDocsIds[id] ?? false}
											classes={{ root: styles.iconRoot }}
											size='small'
											checkedIcon={<CheckboxChecked style={svgIconInlineStyle} />}
											icon={<CheckboxNormal style={svgIconInlineStyle} />}
										/>
									}
								/>
							))}
						</div>
					)}
				</>
			)}

			<div role='input-wrap'>
				<Button className={styles.btn} type='submit'>
					Далее
				</Button>
			</div>
		</form>
	)
}
