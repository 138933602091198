import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { HttpOptions } from '@http/service/options'
import { consoleDebug, isDebug } from '@shared/pipes/is-debug'

import { transformFormToDto } from '../../transform.dto'
import { ApplicationFormDto } from '../../types'

export const updateExistedLead = (form: ApplicationFormDto) => {
	if (isDebug()) {
		return consoleDebug('[Bonus form] - prevent send form')
	}

	return httpService.patch(
		apiUrls.lead.update(),
		transformFormToDto(form),
		new HttpOptions().setHeader('Content-type', 'application/merge-patch+json')
	)
}

export const logout = () => {
	if (isDebug()) {
		return consoleDebug('[Bonus form] - prevent send form')
	}

	return httpService.post(apiUrls.esia.logout())
}
