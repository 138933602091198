import * as React from 'react'
import cx from 'clsx'

import styles from './Card.module.scss'

interface Props {
	className?: string
	header?: React.ReactNode
}

export const Card = ({ children, className, header }: React.PropsWithChildren<Props>) => {
	return (
		<div className={cx(styles.root, className)}>
			{header && <h3 className={styles.h3}>{header}</h3>}
			{children}
		</div>
	)
}
