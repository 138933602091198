import { useRouter } from 'next/router'

export const useRedirect = (isSessionActive = null as null | boolean) => {
	const router = useRouter()

	if (isSessionActive === null) return

	if (typeof window !== 'undefined' && !isSessionActive) {
		router.push('/')
	}
}
