import { ApplicationForm as Cross } from './cross'
import { ApplicationForm as CrossNew } from './cross-new'
import { ApplicationForm as Local } from './local'

/**
 * @Component `Cross` — кросс-доменная форма (шаг 1 и 2)
 * @Component `Local` — локальная форма (шаг 3 и 4)
 */
export const ApplicationForms = {
	Cross,
	CrossNew,
	Local,
}
