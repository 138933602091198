import { transformPhoneToDto } from '@http/service/shared-transformers'

import { ApplicationFormDto } from './types'

const defaultToApi = (value: any) => {
	if (typeof value === 'string') {
		const trimmedValue = value.trim()
		return trimmedValue || null
	}

	return value == null ? null : value
}

export const transformFormToDto = (form: ApplicationFormDto) => {
	return {
		participants: form.participants.map((item, index) => ({
			name: defaultToApi(item.name),
			surname: defaultToApi(item.surname),
			patronymic: defaultToApi(item.patronymic),
			phone: transformPhoneToDto(item.phone),
			email: defaultToApi(item.email),
			role: index === 0 ? 'APPLICANT' : 'OWNER',
		})),
		facilities:
			form.sameAddresses === false && form.address ? [{ address: form.address.value }] : undefined,
	}
}
