import * as React from 'react'
import { SuccessCard } from '@shared/components/forms/application/local/components/SuccessCard'
import { Image } from '@shared/components/image'
import { ExternalPaths } from '@shared/menu-items/paths'
import PatternBlue from '@shared/svg/patterns/pattern-blue.svg'
import Link from 'next/link'

import styles from './style.module.scss'

const links = [
	{
		label: 'Журнал «Процент»: «Плюсы и минусы кредита под залог недвижимости»',
		name: 'preview-1.png',
		href: 'https://journal.credit.club/kak-vzyat-dengi-pod-zalog-nedvizhimosti',
	},
	{
		label: 'Журнал «Процент»: «Как на самом деле работает залоговый кредит»',
		name: 'preview-2.png',
		href: 'https://journal.credit.club/kak-rabotaet-zalogovyj-kredit',
	},
	{
		label: 'Квартира в залоге. Что с ней теперь будет?',
		name: 'preview-3.jpg',
		href: 'https://journal.credit.club/kvartira-v-zaloge',
	},
]

const videos = [
	'https://www.youtube.com/embed/-Te5tWF2U_U',
	'https://www.youtube.com/embed/Y5yfuPPN2hA',
]

export const Success = () => {
	return (
		<SuccessCard>
			<div className={styles.header}>
				<h3>
					Спасибо! <br /> Заявка на кредит сформирована.
				</h3>
				<PatternBlue className={styles.pattern} />
			</div>
			<div className={styles.contentWrap}>
				<h3>Полезная информация о кредитах</h3>
				<div className={styles.contentLinks}>
					{links.map(({ label, href, name }) => (
						<a
							className={styles.listItem}
							href={href}
							target='_blank'
							rel='noopener noreferrer'
							key={label}
						>
							<div className={styles.imageContainer}>
								<Image name={name} />
							</div>
							<span className={styles.link}>{label}</span>
						</a>
					))}
				</div>
			</div>
			<a
				href='https://journal.credit.club/loans'
				target='_blank'
				rel='noopener noreferrer'
				className={styles.mainLink}
			>
				Читать все статьи про кредиты
			</a>
			<div className={styles.services}>
				{videos.map((src) => (
					<iframe
						key={src}
						src={src}
						title='YouTube video player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					/>
				))}
			</div>
			<a
				href={ExternalPaths.companyYoutubeChannel}
				target='_blank'
				rel='noopener noreferrer'
				className={styles.mainLink}
			>
				Смотреть все ролики
			</a>
			<br />
			<div className={styles.back}>
				<Link href='/' className='main-link'>
					На главную
				</Link>
			</div>
		</SuccessCard>
	)
}
