import * as React from 'react'
import { Loading } from '@shared/components/loading'
import { AnimateSharedLayout, motion } from 'framer-motion'

import { Layout } from '../components/Layout'

import { useFormCookie, useRedirect, useSessionData, useSteps } from './hooks'
import { Bonus, ConfirmApplication, Success } from './steps'
import { StepKeys } from './types'

export interface ApplicationFormContextDto {
	isSessionActive: boolean
	userDocument: { name: string; base64: string } | null
	stepController: ReturnType<typeof useSteps>
	cookieController: ReturnType<typeof useFormCookie>
	formController: ReturnType<typeof useSessionData>['form']
	setOptionalDocsIds: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
	optionalDocsIds: Record<string, boolean>
}

const stepsComponents: Record<StepKeys, () => JSX.Element> = {
	'confirm-application': ConfirmApplication,
	bonus: Bonus,
	success: Success,
}

export const ApplicationFormContext = React.createContext<ApplicationFormContextDto>(null!)

export const ApplicationForm = () => {
	const session = useSessionData()
	const steps = useSteps(session.forcedStep!)
	const cookies = useFormCookie(steps.current)
	const [optionalDocsIds, setOptionalDocsIds] = React.useState<Record<string, boolean>>({})

	useRedirect(session.active)

	const Step = stepsComponents[steps.current] || Loading

	const contextValue: ApplicationFormContextDto = {
		userDocument: session.userDocument,
		isSessionActive: !!session.active,
		setOptionalDocsIds,
		optionalDocsIds,
		cookieController: cookies,
		formController: session.form,
		stepController: steps,
	}

	const motionProps: React.ComponentProps<typeof motion.div> = {
		key: 1,
		initial: { opacity: 0 },
		animate: { opacity: 1 },
		transition: { delay: 0.2 },
	}

	return (
		<ApplicationFormContext.Provider value={contextValue}>
			<Layout>
				<AnimateSharedLayout>
					<motion.div {...motionProps}>
						<Step />
					</motion.div>
				</AnimateSharedLayout>
			</Layout>
		</ApplicationFormContext.Provider>
	)
}
