import * as React from 'react'

import styles from './Info.module.scss'

const list = [
	'Повысить вероятность одобрения\nзаявки в 3 раза.',
	'Значительно увеличить сумму\nпо кредиту.',
	'Получить возможное предложение со сниженным платежом и процентной ставкой. ',
]

export const Info = () => {
	return (
		<div className={styles.info}>
			<h2 className={styles.h2}>
				Повысьте вероятность <br /> одобрения заявки
			</h2>
			<div className={styles.content}>
				<div>
					<div className={styles.indentM}>
						Добавьте информацию о квартире, чтобы получить ещё одно предложение по кредиту. С
						помощью залогового займа вы сможете:
					</div>
					<div className={styles.indentS}>
						{list.map((text, index) => (
							<div key={index} className={styles.row}>
								<div className={styles.pointWrap}>
									<span className={styles.point}>{index + 1}</span>
								</div>
								<div className={styles.listText}>{text}</div>
							</div>
						))}
					</div>
				</div>
				<div className={styles.desktopFrame}>
					<h4 className={styles.times}>в 3 раза</h4>
					выше&nbsp;вероятность одобрения в <br /> сравнении с кредитом без обеспечения*
				</div>
			</div>
			<div className={styles.indentM}>
				Информация будет полезна для подбора возможного более выгодно предложения.
			</div>
			<div className={styles.frame}>
				<h4 className={styles.times}>в 3 раза</h4>
				выше вероятность одобрения в сравнении с кредитом без обеспечения*
			</div>
		</div>
	)
}
