import { formatPhone } from '@shared/utils/helpers'

import { ApplicationFormDto } from '../../types'

export const transformDtoToForm = (data?: Record<string, any>): ApplicationFormDto => ({
	sameAddresses: true,
	owner: 'single',
	participants: [
		{
			fullname: [
				data?.participants[0]?.surname,
				data?.participants[0]?.name,
				data?.participants[0]?.patronymic,
			]
				.filter(Boolean)
				.join(' '),
			name: data?.participants[0]?.name ?? '',
			surname: data?.participants[0]?.surname ?? '',
			patronymic: data?.participants[0]?.patronymic ?? '',
			email: data?.participants[0]?.email ?? '',
			phone: formatPhone(data?.participants[0]?.phone ?? '', true),
		},
	],
	address: data?.participants[0]?.residenceAddress || null,
})
