import * as React from 'react'

import { StepKeys } from '../../types'

interface StepMove {
	forward: StepKeys | null
	order: number
}

const stepOrder = ['confirm-application', 'bonus', 'success'] as StepKeys[]

const steps = stepOrder.reduce((acc, curr, index) => {
	switch (curr) {
		case 'confirm-application': {
			acc['confirm-application'] = {
				forward: 'bonus',
				order: index + 1,
			}
			return acc
		}
		case 'bonus': {
			acc['bonus'] = {
				forward: 'success',
				order: index + 1,
			}
			return acc
		}
		case 'success': {
			acc['success'] = {
				forward: null,
				order: index + 1,
			}
			return acc
		}
		default:
			return acc
	}
}, {} as Record<StepKeys, StepMove>)

export const useSteps = (forceStep: StepKeys) => {
	const [step, setStep] = React.useState(forceStep)

	React.useEffect(() => {
		setStep(forceStep)
	}, [forceStep])

	return {
		go: {
			forward: steps[step]?.forward ? () => setStep(steps[step].forward!) : null,
		},
		order: steps[step]?.order ? steps[step]?.order + 2 : null,
		current: step,
		total: 4,
	}
}
