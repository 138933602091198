import * as React from 'react'
import { DadataAddress, DadataFio, DaDataSuggestion } from '@http/dadata/models'
import { transformFioToDto } from '@http/service/shared-transformers'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { Autocomplete } from '@shared/components/autocomplete'
import { Button } from '@shared/components/button'
import { Checkbox } from '@shared/components/checkbox'
import { TextField } from '@shared/components/text-field'
import { useAsyncAutocomplete } from '@shared/hooks/use-async-autocomplete'
import {
	DadataAddressRequestRunner,
	ExternalDadataFioRequestRunner,
} from '@shared/hooks/use-async-autocomplete/request-runners'
import { usePhoneMask } from '@shared/hooks/use-phone-mask'
import RadioActive from '@shared/svg/radio-active.svg'
import RadioInactive from '@shared/svg/radio-inactive.svg'
import { FieldArray, Form, FormikProvider, useFormik, useFormikContext } from 'formik'

import { ApplicationFormContext } from '../../Application'
import { Card } from '../../components/Card'
import { ApplicationFormDto, Participant } from '../../types'

import { updateExistedLead } from './api'
import { Info } from './Info'

import styles from './Bonus.module.scss'

const svgIconInlineStyle = { width: 'initial', height: 'initial' }
const participant: Participant = {
	email: '',
	fullname: '',
	name: '',
	patronymic: '',
	phone: '',
	surname: '',
}

const CustomRadio = (props: any = {}) => (
	<Radio
		{...props}
		disableRipple
		icon={<RadioInactive style={svgIconInlineStyle} />}
		checkedIcon={<RadioActive style={svgIconInlineStyle} />}
	/>
)

const OwnerInputs = ({
	index,
	addOwner,
	last,
}: {
	index: number
	addOwner: () => void
	last: boolean
}) => {
	const formik = useFormikContext<ApplicationFormDto>()

	const [fioAutocompleteOptions, , handleSetQuery] = useAsyncAutocomplete<
		DaDataSuggestion<DadataFio>
	>(new ExternalDadataFioRequestRunner())

	const fullnameProps: React.ComponentProps<typeof Autocomplete> = {
		label: 'ФИО',
		freeSolo: true,
		autoSelect: false,
		blurOnSelect: false,
		inputRequired: true,
		name: `participant[${index}].fullname`,
		getOptionSelected: React.useCallback((option, value) => value.value === option.value, []),
		onChange: (value) =>
			formik.setValues({
				...formik.values,
				participants: formik.values.participants.map((item, idx) =>
					idx === index ? { ...item, ...transformFioToDto(value, '') } : item
				),
			}),
		options: fioAutocompleteOptions,
		onInputChange: React.useCallback(
			(_, query) => {
				handleSetQuery(query)
				formik.setValues({
					...formik.values,
					participants: formik.values.participants.map((item, idx) =>
						idx === index ? { ...item, ...transformFioToDto(query, '') } : item
					),
				})
			},
			[formik.values]
		),
	}

	const { mask, maskChar, beforeMaskedValueChange } = usePhoneMask()

	const phoneProps: React.ComponentProps<typeof TextField> = {
		name: `participants[${index}].phone`,
		value: formik.values.participants[index].phone,
		label: 'Номер телефона',
		disabled: false,
		inputMode: 'tel',
		required: true,
		mask,
		maskChar,
		beforeMaskedValueChange,
	}

	return (
		<div key={index} role='input-wrap'>
			<div role='input-wrap'>
				<Autocomplete {...fullnameProps} />
			</div>
			<div role='input-wrap' className={styles.indentM}>
				<TextField {...phoneProps} />
			</div>
			{last && (
				<div onClick={addOwner} className={styles.addOwner}>
					Добавить собственника
				</div>
			)}
		</div>
	)
}

export const Bonus = () => {
	const context = React.useContext(ApplicationFormContext)

	const formik = useFormik({
		initialValues: context.formController.values!,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			try {
				await updateExistedLead(values!)
				context.cookieController.update(true)
				context.stepController.go.forward?.()
			} catch {
				setSubmitting(false)
			}
		},
	})

	const [autoCompleteAddressResponse, , handleSetAddressQuery] = useAsyncAutocomplete<
		DaDataSuggestion<DadataAddress>
	>(new DadataAddressRequestRunner())

	const sameProps: React.ComponentProps<typeof Checkbox> = {
		label: 'Совпадет с местом регистрации',
		checked: formik.values.sameAddresses,
		name: 'sameAddresses',
	}

	const addressProps: React.ComponentProps<typeof Autocomplete> = {
		label: 'Адрес',
		autoSelect: false,
		freeSolo: true,
		options: autoCompleteAddressResponse,
		name: 'address',
		onInputChange: React.useCallback((_, value) => handleSetAddressQuery(value), []),
		helperText: 'Регион, город / населённый пункт, улица, дом, квартира',
	}

	const radioGroupProps: React.ComponentProps<typeof RadioGroup> = {
		name: 'owner',
		value: formik.values.owner,
		onChange: (_, value) => {
			if (value === 'one-of') {
				formik.setValues({
					...formik.values,
					owner: value,
					participants: [formik.values.participants[0], participant],
				})
			} else if (value === 'single') {
				formik.setValues({
					...formik.values,
					owner: value,
					participants: [formik.values.participants[0]],
				})
			}
		},
	}

	const radio1Props: React.ComponentProps<typeof FormControlLabel> = {
		value: 'one-of',
		control: <CustomRadio />,
		label: 'Являюсь одним из собственников',
	}

	const radio2Props: React.ComponentProps<typeof FormControlLabel> = {
		value: 'single',
		control: <CustomRadio />,
		label: 'Единственный собственник',
	}

	const handleSkip = React.useCallback(() => {
		context.cookieController.update(true)
		context.stepController.go.forward?.()
	}, [context])

	return (
		<Card
			header={
				<>
					<div className={styles.step}>Последний шаг — бонус</div>
					<div className={styles.hint}>Заявка на кредит</div>
				</>
			}
		>
			<Info />
			<h4 className={styles.indentM}>
				Адрес квартиры для возможности рассмотрения залогового предложения
			</h4>
			<FormikProvider value={formik}>
				<Form>
					<Checkbox {...sameProps} />
					{!formik.values.sameAddresses && (
						<div className={styles.address}>
							<Autocomplete {...addressProps} />
						</div>
					)}
					<div role='input-wrap' className={styles.indentS}>
						<h4 className={styles.indentM}>Собственники</h4>
						<RadioGroup {...radioGroupProps}>
							<FormControlLabel {...radio1Props} />
							<FormControlLabel {...radio2Props} />
						</RadioGroup>
					</div>
					{formik.values.owner === 'one-of' && (
						<div>
							<div>
								Добавьте информацию о собственнике. Предупредите человека: ему придёт сообщение с
								ссылкой на Госуслуги для дополнения вашей заявки и передачи согласия на обработку
								данных.
							</div>
							<FieldArray
								name='participants'
								render={(arrayHelpers) =>
									formik.values.participants.map((item, index, array) => {
										if (index === 0) return null

										const ownerInputsProps: React.ComponentProps<typeof OwnerInputs> = {
											index: index,
											last: array.length - 1 === index,
											addOwner: () => arrayHelpers.push(participant),
										}

										return <OwnerInputs key={index} {...ownerInputsProps} />
									})
								}
							/>
						</div>
					)}
					<div className={styles.controls} role='input-wrap'>
						<Button type='submit' disabled={formik.isSubmitting}>
							Воспользоваться бонусом
						</Button>
						<Button
							type='button'
							size='l'
							className={styles.skip}
							variant='link'
							onClick={handleSkip}
						>
							Пропустить
						</Button>
					</div>
				</Form>
			</FormikProvider>
		</Card>
	)
}
