const leadProductTypes = [
	'CAR_SECURED_LOAN',
	'CONSUMER_CREDIT',
	'MORTGAGE',
	'REAL_ESTATE_LOAN',
] as const
type ProductTypesTuple = typeof leadProductTypes
export type LeadProductType = ProductTypesTuple[number]

export const isLeadProductType = (string: unknown): boolean => {
	if (typeof string !== 'string') {
		return false
	}
	const strValue = string as string
	return leadProductTypes.includes(strValue as LeadProductType)
}
