import * as React from 'react'
import { useCookies } from 'react-cookie'
import { LOCAL_APPLICATION_FORM_COOKIE } from '@constants/cookies'

import { ApplicationFormCookie, StepKeys } from '../../types'

export const useFormCookie = (step: StepKeys) => {
	const [cookies, setCookie] = useCookies([LOCAL_APPLICATION_FORM_COOKIE])

	const statuses = (cookies[LOCAL_APPLICATION_FORM_COOKIE] ?? {
		'confirm-application': false,
		bonus: false,
		success: false,
	}) as ApplicationFormCookie

	const update = React.useCallback(
		(value: boolean) => {
			setCookie(
				LOCAL_APPLICATION_FORM_COOKIE,
				Object.assign({}, statuses, {
					[step]: value,
				}),
				{
					domain: process.env.NODE_ENV === 'development' ? undefined : '.credit.club',
				}
			)
		},
		[cookies, step, setCookie]
	)

	return {
		update,
		finished: !!statuses[step],
	}
}
