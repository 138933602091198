import * as React from 'react'
import { useCookies } from 'react-cookie'
import { CROSS_APPLICATION_FORM_COOKIE, LOCAL_APPLICATION_FORM_COOKIE } from '@constants/cookies'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { ApplicationFormSteps } from '@shared/models/applicationFormSteps'

import { ApplicationFormCookie, ApplicationFormDto, StepKeys } from '../../types'

import { transformDtoToForm } from './transform.dto'
// import { isProduction } from '@shared/pipes/config'

export const useSessionData = () => {
	const [cookies, setCookies, removeCookie] = useCookies([
		LOCAL_APPLICATION_FORM_COOKIE,
		CROSS_APPLICATION_FORM_COOKIE,
	])
	const [formValues, setFormValues] = React.useState<ApplicationFormDto | null>(null)
	const [active, setActive] = React.useState<boolean | null>(null)
	const [forcedStep, setForcedStep] = React.useState<StepKeys | null>(null)
	const [userDocument, setUserDocument] = React.useState<{ name: string; base64: string } | null>(
		null
	)

	React.useEffect(() => {
		if (cookies[LOCAL_APPLICATION_FORM_COOKIE]?.bonus) {
			removeCookie(LOCAL_APPLICATION_FORM_COOKIE)
			removeCookie(CROSS_APPLICATION_FORM_COOKIE)
			httpService.post(apiUrls.esia.logout())
		}
	}, [cookies[LOCAL_APPLICATION_FORM_COOKIE]?.bonus])

	const initSessionData = React.useCallback(
		async (onSuccess?: () => void, shouldFetchUserDocument = false) => {
			try {
				const lead = await httpService.get<Record<string, any>>(apiUrls.lead.get())

				// if (!isProduction) {
				// 	await httpService.post<Record<string, any>>(apiUrls.esia.test(), {
				// 		preset: 'FULL_APPLICATION',
				// 		phone: '9995114341',
				// 		personId: '6074331575e3867e77a81cf6',
				// 		username: '11111111',
				// 		ipAddress: '127.0.0.1',
				// 		context: {},
				// 	})
				// }

				const user = await httpService.get<Record<string, any>>(apiUrls.getUser())

				if (!user.esiaSession) throw new Error()

				if (shouldFetchUserDocument) {
					const documents = await httpService.get<Record<string, any>[]>(apiUrls.esia.documents())

					if (documents[0]) {
						const documentContent = await httpService.get<Record<string, any>>(
							apiUrls.esia.document(documents[0].id)
						)
						setUserDocument({ name: documents[0].name, base64: documentContent.content })
					}
				}

				setFormValues(transformDtoToForm(lead))
				setCookies(
					CROSS_APPLICATION_FORM_COOKIE,
					Object.assign({}, cookies[CROSS_APPLICATION_FORM_COOKIE], {
						'esia-auth': true,
						primary: true,
					} as Record<ApplicationFormSteps['AF1'], boolean>),
					{
						domain: process.env.NODE_ENV === 'development' ? undefined : '.credit.club',
					}
				)
				setActive(true)
				onSuccess && onSuccess()
			} catch {
				removeCookie(LOCAL_APPLICATION_FORM_COOKIE)
				removeCookie(CROSS_APPLICATION_FORM_COOKIE)
				setActive(false)
			}
		},
		[]
	)

	React.useEffect(() => {
		initSessionData(() => {
			const _c = cookies[LOCAL_APPLICATION_FORM_COOKIE] as ApplicationFormCookie

			if (_c && _c['confirm-application']) {
				setForcedStep('bonus')
			} else if (_c?.bonus) {
				removeCookie(LOCAL_APPLICATION_FORM_COOKIE)
				removeCookie(CROSS_APPLICATION_FORM_COOKIE)
				setForcedStep('success')
			} else {
				setForcedStep('confirm-application')
			}
		}, true)
	}, [])

	return {
		form: {
			refetch: initSessionData,
			values: formValues,
			setValues: setFormValues,
		},
		userDocument,
		forcedStep,
		active,
	}
}
